import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home', //首页
    name: 'home',
    component: () => import ('../views/Home.vue'),
    meta: {
      title: 'Home',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/kjDetails', //矿机详情
    name: 'kjDetails',
    component: () => import('../views/kjDetails.vue'),
    meta: {
      title: 'Miner details',
      requireAuth: true, // 判断是否需要登录
    },
  },
  // {
  //   path: '/ethDetails', //ETH详情
  //   name: 'ethDetails',
  //   component: () => import('../components/home/ethDetails.vue'),
  //   meta: {
  //     title: '第二期详情',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  // {
  //   path: '/ipfsDetails', //IPFS详情
  //   name: 'ipfsDetails',
  //   component: () => import ('../components/home/ipfsDetails.vue'),
  //   meta: {
  //     title: '第四期详情',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  // {
  //   path: '/mill', //矿机
  //   name: 'mill',
  //   component: () => import('../views/Mill.vue'),
  //   meta: {
  //     title: '矿机',
  //     requireAuth: true, // 判断是否需要登录
  //   },
  // },
  {
    path: '/kjList', //矿机列表
    name: 'kjList',
    component: () => import('../views/kjList.vue'),
    meta: {
      title: 'List of miners',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/millList', //矿机列表
    name: 'millList',
    component: () => import('../components/mill/millList.vue'),
    meta: {
      title: 'My list of miners',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/more', //了解更多
    name: 'more',
    component: () => import('../components/more.vue'),
    meta: {
      title: 'Learn more',
      requireAuth: false, // 判断是否需要登录
    },
  },
  {
    path: '/my', //我的
    name: 'my',
    component: () => import ('../views/My.vue'),
    meta: {
      title: 'Me',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/purse', //我的
    name: 'purse',
    component: () => import('../views/purse.vue'),
    meta: {
      title: 'purse',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/help', //帮助中心
    name: 'help',
    component: () => import('../views/help.vue'),
    meta: {
      title: 'Help Center',
      requireAuth: false, // 判断是否需要登录
    },
  },
  {
    path: '/about', //关于我们
    name: 'about',
    component: () => import('../views/about.vue'),
    meta: {
      title: 'About Us',
      requireAuth: false, // 判断是否需要登录
    },
  },
  {
    path: '/setting', //设置
    name: 'setting',
    component: () => import('../views/setting.vue'),
    meta: {
      title: 'setting',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/login', //登陆
    name: 'login',
    component: () => import('../components/Login/index.vue'),
    meta: {
      title: 'login',
    },
  },
  {
    path: '/sign', //注册
    name: 'sign',
    component: () => import('../components/sign/index.vue'),
    meta: {
      title: 'sign',
    },
  },
  {
    path: '/realName', //我的-实名认证
    name: 'realName',
    component: () => import ('../components/my/realName.vue'),
    meta: {
      title: 'realName',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/feedBack', //我的-联系客服
    name: 'feedBack',
    component: () => import ('../components/my/feedBack.vue'),
    meta: {
      title: 'Contact customer service',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/topup', //我的-钱包-充值
    name: 'topup',
    component: () => import('../views/topup.vue'),
    meta: {
      title: 'topup',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/Withdrawal', //我的-钱包-提现
    name: 'Withdrawal',
    component: () => import ('../views/Withdrawal.vue'),
    meta: {
      title: 'Withdrawal',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/newelectricity', //我的-电费
    name: 'newelectricity',
    component: () => import('../views/newelectricity.vue'),
    meta: {
      title: '电费',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/electricitytopup', //我的-电费-充值
    name: 'electricitytopup',
    component: () => import('../views/electricitytopup.vue'),
    meta: {
      title: 'Electric charge',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/MentionRecord', //我的-交易记录
    name: 'MentionRecord',
    component: () => import ('../views/MentionRecord.vue'),
    meta: {
      title: 'Transaction record',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/electricity', //我的-rmb交易记录
    name: 'electricity',
    component: () => import ('../components/my/electricity.vue'),
    // component: () => import ('../components/my/feedBack.vue'),
    meta: {
      title: 'Transaction record',
      requireAuth: true, // 判断是否需要登录
    },
  },
  {
    path: '/MentionRecordDetail', //我的-交易详情
    name: 'MentionRecordDetail',
    component: () => import ('../views/MentionRecordDetail.vue'),
    meta: {
      title: 'Detail',
      requireAuth: true, // 判断是否需要登录
    },
  },
];

const router = createRouter ({
  history: createWebHashHistory (),
  routes,
});

export default router;
